import React from 'react';

import {shallowEqual} from 'recompose'; //TODO: there's no explicit dependency on fbjs
import {EventEmitter, EventSubscription} from 'fbemitter';

export interface DataLoader<P,S,D> extends React.Component<P,S> {
};

interface DLWrappedClass<P,S,D> extends React.ComponentClass<ComponentProps<P,S,D>, any> {}

type Trackable = EventEmitter; //Data store objects must be of this type

// The P & S are parital here because the render class can irgnore those 
// args if it wants to
export type ComponentProps<P,S,D> = P & S & D & {
  data_loader: DataLoader<P,S,D>
};

export default function<P,S,D>(
    Component: React.ComponentClass<ComponentProps<P,S,D>, any>, 
    storeProps: Array<keyof P>,
    getDataFunc: (props: P, state: S, data_loader: DataLoader<P,S,D>) => D
  ) : React.ComponentClass<P,S> {
  
  // type mergedProps = P&S&{children?: React.ReactNode};
  // type propsToComponent = P & S & {children?: React.ReactNode, data_loader: DataLoader<P,S,D>}
  // type propsToDataHandler = {props: P, state: S};

  const DataHandlingClass = class extends React.PureComponent<P,S> {
    listenerTokens: Array<EventSubscription>;
    lastTrackingRun: {[index: string]: number};
    data: D | undefined;

    constructor(props: P, context?: any) {
      super(props,context);

      this.listenerTokens = [];
      this.lastTrackingRun = {};
    }

    componentDidMount() {
      this.listenerTokens = storeProps.map((p) => (
        (this.props[p] as unknown as EventEmitter).addListener('change', (e: any) => (this.handleStoresChanged(e)))
      ));
    }
    componentWillUnmount() {
      this.listenerTokens.forEach((t) => {
        t.remove();
      });
    }

    handleStoresChanged(e: any) {
      const {data: newData, tracking: tracking} = this.trackedData();
      const ltr = this.lastTrackingRun;

      if(Object.keys(tracking).some((k) => (!ltr[k] || (tracking[k] > ltr[k]) ))) {
        this.lastTrackingRun = tracking;
        this.data = newData;
        this.forceUpdate();  
      } else {
        //Do not update the data because nothing was new 
      }
    }

    // Returns the value from func() if any data fetched from a store named by StoreSet accesses data newer than the last time squenceTracking was used
    // Returns an empty object otherwise, for easy merging into a new "state" object 
    // storeSet defaults to all stores
    private trackedData() {
      const storeSet = storeProps;
      let seq_values : {[index: string]: number} = {};
      let subscriptions = storeProps.map((p) => (
        (this.props[p] as unknown as EventEmitter).addListener('loadSequence', (seq_value: number) => (
          seq_values[p as string] = Math.max( (seq_values[p as string] || 0) as number, seq_value)
        ))
      ));

      let ret;
      try {
        ret = getDataFunc(this.props, this.state, this);
      } finally {
        subscriptions.forEach((s) => (s.remove()));
      }

      return {data: ret,tracking: seq_values};
    }

    render() {
      //Perf optimization, this is set if the render is caused by a change event in the data store (and not props/state change)
      if(this.data) {
        var data = this.data;
        this.data = undefined;
      } else {
        var {data: data, tracking: tracking} = this.trackedData();
        this.lastTrackingRun = tracking;
      }

      const wrappedProps = Object.assign({}, {data: data, data_loader: this, props: this.props}, this.props, this.state, data);
      return <Component {...wrappedProps}></Component>;
    }
  };

  Object.defineProperty (DataHandlingClass, 'name', {value: `${Component.name} (Data Loader)`})
  return DataHandlingClass;
}