"use strict";

import RestfulModelStore, { TxnResult, Txn } from '../lib/RestfulModelStore';
import MemoryStore       from '../lib/MemoryStore';

import React from 'react';
import { Button, Table, PageHeader, Navbar, FormGroup, FormControl, Panel, Modal, Grid, Row, Col, ControlLabel, HelpBlock, InputGroup, ListGroup, ListGroupItem, Glyphicon } from 'react-bootstrap';
import { Session } from '../lib/Models';

type IncomingProps = {store: RestfulModelStore};
type ViewState = {
  email?: string,
  password?: string,
  create_txn?: Txn
};

export default class LoginForm extends React.Component<IncomingProps,ViewState> {
  constructor(props: IncomingProps) {
    super(props);
    this.state = {};
  }

  createSession(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const state = this.state;
    const props = this.props;

    const new_obj = {
      greenbits_username: state.email,
      greenbits_password: state.password
    }
    this.setState({
      create_txn: props.store.s(Session).create(new_obj)
    });
  }

  handleInputChange(e: any) {
    this.setState({[e.target.id]: e.target.value});
  }

  render() {
    const state = this.state;
    const props = this.props;

    var create_status = state.create_txn && props.store.txn_status(state.create_txn)
    console.log("Create_status", create_status)
    let message;
    if (create_status && create_status.status == 'error') {
      message = 'Invalid email address or password.';
    }
    return (
      <form onSubmit={(e) => (this.createSession(e))}>
        <Grid>
          <Row>{message}</Row>
          <Row>
            <FormGroup controlId="email">
              <ControlLabel>Email Address</ControlLabel>
              <FormControl type="email" onChange={(e) => (this.handleInputChange(e))}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup controlId="password">
              <ControlLabel>Password</ControlLabel>
              <FormControl type="password" onChange={(e) => (this.handleInputChange(e))}/>
            </FormGroup>
          </Row>
          <Row><Button type="submit" bsStyle="primary">Login</Button></Row>
        </Grid>
      </form>
    );
  }

}
