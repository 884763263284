"use strict";

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Nav, NavItem, Glyphicon, Grid, Row, Col, Panel, Popover, OverlayTrigger, InputGroup, Button } from 'react-bootstrap';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import classNames from 'classnames';

import LoaderWrapper, { ComponentProps } from '../lib/DataLoader2';

import SummaryTab from './VendorsPane/SummaryTab';
import VendorTab  from './VendorsPane/VendorTab';

import './VendorsPane/vendors_pane_styles.scss';
import RestfulModelStore, { ReifiedQueryResult, RecordId } from '../lib/RestfulModelStore';
import {EntityFields, Entity, Company} from '../lib/Models';

type IncomingProps = {store: RestfulModelStore, ux_state: any, company_id: string};
type LoaderState   = {};
type LoaderData    = {
  active_tab: "Summary" | number,
  vendors: ReifiedQueryResult<EntityFields>,
  entity_id?: number
}


class VendorsPane extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>> {

  handleVendorSelect(key : string | number) {
    this.props.ux_state.set('vendors_pane.active_tab', key);
  }

  openTab(vendor_id: RecordId) {
    const ux_state  = this.props.ux_state;
    const open_tabs = (ux_state.get('vendors_pane.open_tabs') || []) as RecordId[];

    if(!open_tabs.some((t) => (t === vendor_id)))
      ux_state.set('vendors_pane.open_tabs', open_tabs.concat(vendor_id));
  }

  closeTab(vendor_id: RecordId) {
    const ux_state  = this.props.ux_state;
    const open_tabs = (ux_state.get('vendors_pane.open_tabs') || []) as RecordId[];

    ux_state.set('vendors_pane.open_tabs', open_tabs.filter((t) => (t !== vendor_id)));
  }

  doVendorOpen(vendor_id: RecordId) {
    this.openTab(vendor_id);
    this.props.ux_state.set('vendors_pane.active_tab', vendor_id);
  }

  render() {
    const props = this.props;

    const active_tab = props.active_tab;
    const vendors    = props.vendors;

    if(!props.entity_id) {
      return <div>Loading…</div>;
    }

    return <Grid fluid style={{padding: 0}}>
      <Row><Col>
        <Nav bsStyle="tabs" activeKey={props.active_tab} onSelect={k => this.handleVendorSelect(k as any)}>
          <NavItem key={"Summary"} eventKey="Summary">
            Summary
          </NavItem>
          {
            vendors.map((v) => ( 
              <NavItem key={v.id as number} eventKey={v.id} title="Item">
                <span>{v._found ? v.name : "Loading…"}<button type="button" onClick={(e) => (this.closeTab(v.id))} className="tab-close-button" data-dismiss="alert" aria-label="Close" style={{paddingLeft: '1em'}}><span aria-hidden="true">&times;</span></button></span>
              </NavItem>
            ))
          }
        </Nav>
      </Col></Row>
      <Row><Col>
        {active_tab === "Summary" ? <SummaryTab entity_id={props.entity_id} store={props.store} doVendorOpen={this.doVendorOpen.bind(this)}/> : ""}
        {active_tab && active_tab != "Summary" ? <VendorTab key={active_tab} vendor_id={active_tab} entity_id={props.entity_id} store={props.store} ux_state={props.ux_state}/> : ""}
      </Col></Row>
    </Grid>;
  }
}

export default LoaderWrapper(VendorsPane, ['store', 'ux_state'], (props, state, dataLoader) => {
  const store    = props.store;
  const ux_state = props.ux_state;

  const open_tabs  = (ux_state.get('vendors_pane.open_tabs') || []) as string[]; //Really subtle would-be perf bug here
  const active_tab = ux_state.get('vendors_pane.active_tab');
  const entity = store.m(Company).fetch(props.company_id); //TODO: Really should be using entity_id from top to bottom
  const ret = {
    active_tab: open_tabs.some((v) => (v === active_tab)) ? active_tab : 'Summary',
    vendors: store.m(Entity).fetchMany(open_tabs),
    entity_id: entity._found ? entity.entity_id : undefined
  };
  return ret;
});


