import { ModelDefinition, SingletonModelDefinition, RecordId, Record} from './RestfulModelStore';


export type EntityFields = {
  id: number,
  name: string,
  analysis_date: string,
  job_processing?: string,
  job_progress?: [number,number]
}
export const Entity: ModelDefinition<EntityFields> = {
  name: "entity",
  inflections: {
    plural: 'entities',
    title: "Entity"
  },
  singleton: false
};

export type InventoryTargetFields = {
  id: RecordId<number>,
  entity_id: number,
  vendor_id: number,
  days_supply: number,
  strain_count: number,
  product_type_id: string,
  flower_type_id: number,
  package_size_ids: string[],
  target_groups: any[],
  failed_target: boolean,
  on_target: boolean
};
export const InventoryTarget: ModelDefinition<InventoryTargetFields> = {
  name: 'inventory_target',
  inflections: {
    plural: 'inventory_targets',
    title: 'InventoryTarget'
  },
  singleton: false
};

export type VendorSummaryFields = {
  id: string,
  entity_id: number, 
  date: string, 
  trailing_days: number, 
  source_vendor_id: number, 
  product_type: string, 
  contribution_margin: string, 
  gross_sales: string, 
  transactions: number
}
export const VendorSummary: ModelDefinition<VendorSummaryFields> = {
  name: 'vendor_summary',
  inflections: {
    plural: 'vendor_summaries',
    title: 'VendorSummary'
  },
  singleton: false
};

export const PackageSize: ModelDefinition<{
  id: string,
  display_text: string
  gram_weight: number
}> = {
  name: "package_size",
  inflections: {
    plural: 'package_sizes',
    title: 'PackageSize'
  },
  singleton: false
}

export const ProductType: ModelDefinition<{id: string, name: string}> = {
  name: 'product_type',
  inflections: {
    plural: 'product_types',
    title: 'ProductType'
  },
  singleton: false
};

export const FlowerType: ModelDefinition<{
  id: number,
  name: string
}> = {
  name: "flower_type",
  inflections: {
    plural: 'flower_types',
    title: "FlowerType"
  },
  singleton: false
}

export type SessionFields = {
  id: number, //TODO: Probably doesn't exist
  current_user_id: number,
  //Hackey used only by the create
  greenbits_username?: string,
  greenbits_password?: string
}
export const Session: SingletonModelDefinition<SessionFields> = {
  name: 'session',
  singleton: true,
  inflections: {
    plural: 'sessions',
    title: 'Session'
  }
};

export type CompanyFields = {
  id: RecordId<string>,
  name: string, 
  entity_id: number
}
export const Company: ModelDefinition<CompanyFields> = {
  name: 'company',
  inflections: {
    plural: 'companies',
    title: 'Company'
  },
  singleton: false
};

export type UserFields = {
  id: number,
  name: string,
  accessible_company_ids: string[]
};
export const User: ModelDefinition<UserFields> = {
  name: "user",
  inflections: {
    plural: 'users',
    title: 'User'
  },
  singleton: false
};

export type ProductFields = {
  id: RecordId<number>,
  name: string,
  category: string,
  quantity: number,
  dollar_sell_price: string,
  flower_type_id?: number,
  package_size_id: string,
  markup: number
};

export const Product: ModelDefinition<ProductFields> = {
  name: "product",
  inflections: {
    plural: 'products',
    title: 'Product'
  },
  singleton: false
};

export type CorporateSummaryFields = {
  id: string,
  gross_sales: number,
  sold_cogs: number,
  pricing: {
    price_per_gram: number[],
    skus_density: number[],
    units_density: number[],
    margin_density: number[],
    bucket_averages: {
      [package_size: string]: number
    }
  }
};
export const CorporateSummary: ModelDefinition<CorporateSummaryFields> = {
  name: "corporate_summary",
  inflections: {
    plural: 'corporate_summaries',
    title: 'CorporateSummary'
  },
  singleton: false
};

export type ScheduledSalesFields = {
  id: number,
  start: string,
  end?: string,
  sale_price: string,
  end_price?: string,
  allow_discounts: boolean,
  greenbits_product_id: string
};
export const ScheduledSale: ModelDefinition<ScheduledSalesFields> = {
  name: "scheduled_sale",
  inflections: {
    plural: 'scheduled_sales',
    title: 'ScheduledSale'
  },
  singleton: false
};

export const Subscription: ModelDefinition<{
  id: number
}> = {
  name: 'subscription',
  inflections: {
    plural: 'subscriptions',
    title: 'Subscription'
  },
  singleton: false
};

export const ProductSalesVelocity: ModelDefinition<{
  id: string | number,
  entity_id: string | number,
  date: string,
  product_id: string | number,
  trailing_days: number,
  exhaust_date: string,
  days_in_stock: number,
  sold_units: number,
  velocity: number
}> = {
  name: "product_sales_velocity",
  inflections: {
    plural: 'product_sales_velocities',
    title: 'ProductSalesVelocity'
  },
  singleton: false
};
