import React from 'react';

import { Button, Checkbox, Table, PageHeader, Navbar, FormGroup, FormControl, Panel, Modal, Grid, Row, Col, ControlLabel, HelpBlock, InputGroup, ListGroup, ListGroupItem, Glyphicon, Pagination } from 'react-bootstrap';
import { Line, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';

import LoaderWrapper, { ComponentProps } from '../lib/DataLoader2';
import RestfulModelStore, { ModelRecord } from '../lib/RestfulModelStore';
import { CorporateSummaryFields, Company, Entity, CorporateSummary } from '../lib/Models';

const $fmt  = new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency'});

type IncomingProps = {store: RestfulModelStore, ux_state?: any, company_id: string};
type LoaderState   = {
};
type LoaderData    = {
  yesterday_summary?: ModelRecord<CorporateSummaryFields>
}

class SelectionGraph extends React.Component<ComponentProps<IncomingProps,LoaderState,LoaderData>> {
  render() {
    const props = this.props;
    
    //For the Selection Graphs
    const yesterday_summary = props.yesterday_summary;
    let data;
    let bucket_averages: { [package_size: string]: number };
    if(yesterday_summary && yesterday_summary._loaded && yesterday_summary._found) {
      data = yesterday_summary.pricing.price_per_gram.map((v, idx) => 
        ({
          price_per_gram: v,
          skus_density:   yesterday_summary.pricing.skus_density[idx], 
          units_density:  yesterday_summary.pricing.units_density[idx],
          margin_density: yesterday_summary.pricing.margin_density[idx]
        })
      );

      bucket_averages = yesterday_summary.pricing.bucket_averages;
    } else {
      bucket_averages = {};
    }

    return <LineChart width={600} height={400} data={data || []}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type={'number'} dataKey={'price_per_gram'}/>
      <YAxis/>
      <Tooltip content={
        (x: any) => {
          return <div style={{backgroundColor: 'white', border: '1px solid #777', padding: "2px"}}>
            <table><tbody>
              { Object.entries(bucket_averages).sort((a,b) => (Number(a[0]) - Number(b[0]))).map((tuple) => (
                <tr key={tuple[0]}><td style={{paddingRight: "2px"}}>{tuple[0]}</td><td>{$fmt.format(Number(tuple[0]) * tuple[1] * x.label)}</td></tr>
              ))}
            </tbody></table>
          </div>;
        }
      }/>
     <Legend verticalAlign="top" height={36}/>
      <Line name="Selection" type="linear" dataKey={"skus_density"}   stroke="#00f" strokeWidth={2} dot={false}/>
      <Line name="Units Sold" type="linear" dataKey={"units_density"}  stroke="#0f0" strokeWidth={2} dot={false}/>
      <Line name="Contribution Margin" type="linear" dataKey={"margin_density"} stroke="#f00" strokeWidth={2} dot={false}/>
    </LineChart>;
  }
}

export default LoaderWrapper(SelectionGraph, ['store'], (props, state, dataLoader) => {
  const store = props.store;

  const company_id  = props.company_id;
  const company = store.m(Company).fetch(company_id);
  const entity  = company._found ? store.m(Entity).fetch(company.entity_id) : undefined;
  const date    = entity && entity._found && entity.analysis_date;

  const yesterday_summary = (date ? props.store.m(CorporateSummary).queryFor('', {
      company_id: company_id,
      date: date
    }) : props.store.emptyLoadingQuery<CorporateSummaryFields>())[0];

  return {
    yesterday_summary: yesterday_summary
  };
});