"use strict";

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Nav, NavItem, Glyphicon, Grid, Row, Col, Panel, Popover, OverlayTrigger, InputGroup, Button } from 'react-bootstrap';
import classNames from 'classnames';

import LoaderWrapper, { ComponentProps } from '../../lib/DataLoader2';

import './summary_tab_styles.scss';
import RestfulModelStore, { ModelRecord, FoundRecord } from '../../lib/RestfulModelStore';
import { Entity, VendorSummary, VendorSummaryFields, EntityFields } from '../../lib/Models';

const $fmt  = new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency'});

type IncomingProps = {
  store: RestfulModelStore, 
  entity_id: number,
  doVendorOpen: (vendor_id: number) => void
};
type LoaderState   = {

};
type ViewSummary = {source_vendor_id: number, date: string, contribution_margin: number, transactions: number, gross_sales: number};
type LoaderData    = {
  vendor_summaries: ViewSummary[]
}

class SummaryTab extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>> {
  render() {
    const props = this.props;

    return <Grid className="vendor-summary-table">
      {
        props.vendor_summaries.map((vs) => (
          <CondensedSummary key={vs.source_vendor_id} summary={vs} doVendorOpen={props.doVendorOpen} store={props.store}/>
        ))
      }
    </Grid>
  }
}

export default LoaderWrapper(SummaryTab, ['store'], (props, state, dataLoader) => {
  const store    = props.store;

  const entity  = store.m(Entity).fetch(props.entity_id);
  const date    = entity && entity._found && entity.analysis_date;

  const expanded_summaries = date ? store.m(VendorSummary).queryFor("",{entity_id: props.entity_id, date: date}) : store.emptyLoadingQuery<VendorSummaryFields>();

  const condensed_summaries = Object.values(expanded_summaries.reduce((h, summary) => {
    if(!summary._found) { return h };
    (h[summary.source_vendor_id] = h[summary.source_vendor_id] || []).push(summary); return h;
  }, {} as {[vendor_id: string]: FoundRecord<VendorSummaryFields>[]})).map((summaries) => (
    summaries.reduce((condensed, summary) => {
      condensed.source_vendor_id = summary.source_vendor_id; condensed.date = summary.date;
      condensed.contribution_margin = (condensed.contribution_margin || 0) + parseFloat(summary.contribution_margin);
      condensed.transactions        = (condensed.transactions || 0) + summary.transactions;
      condensed.gross_sales         = (condensed.gross_sales || 0)  + parseFloat(summary.gross_sales);
      return condensed;
    }, {} as ViewSummary)
  ));

  return {
    vendor_summaries: condensed_summaries
  }
});

// A fragment that represents a rolled-up summary of a single vendor
type CondensedSummaryIncomingProps = {
  store: RestfulModelStore, 
  doVendorOpen: (vendor_id: number) => void,
  summary: ViewSummary
};
type CondensedSummaryLoaderData = { entity: ModelRecord<EntityFields>}
const CondensedSummary = LoaderWrapper(class extends React.PureComponent<ComponentProps<CondensedSummaryIncomingProps,CondensedSummaryLoaderData,{}>> {
  render() {
    const props = this.props;
    const vs    = props.summary;

    return <Fragment>
      <Row className="vendor-summary top-half" onClick={(e) => (props.doVendorOpen(vs.source_vendor_id))}>
        <Col xs={4} md={4} className="vendor-name">{props.entity._found ? props.entity.name : "Loading…"}</Col><Col xs={4} md={8}>3/4 Targets</Col>
      </Row>
      <Row className="vendor-summary bottom-half">
        <Col md={4}>{$fmt.format(vs.contribution_margin)} Contribution Margin</Col>
        <Col md={4}>{vs.transactions} Trasnsactions</Col>
        <Col md={4}>{$fmt.format(vs.gross_sales)} Gross Sales</Col>
      </Row>
    </Fragment>;
  }
}, ['store'], (props, state, dataLoader) => {
  return {
    entity: props.store.m(Entity).fetch(props.summary.source_vendor_id)
  };
});