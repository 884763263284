import PropTypes from 'prop-types';
import React from 'react';

import { Button, Checkbox, Table, PageHeader, Navbar, FormGroup, FormControl, Panel, Modal, Grid, Row, Col, ControlLabel, HelpBlock, InputGroup, ListGroup, ListGroupItem, Glyphicon } from 'react-bootstrap';
import DatePicker from "react-bootstrap-date-picker";

import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';

import DataLoader from '../lib/DataLoader';

import SelectionGraph from './SelectionGraph';
import RestfulModelStore, { ModelRecord, ReifiedQueryResult, RecordId, Txn, ExtantRecord, FoundRecord } from '../lib/RestfulModelStore';
import { EntityFields, UserFields, CompanyFields, SessionFields, ProductFields, Product, ScheduledSale, ScheduledSalesFields } from '../lib/Models';
import LoaderWrapper, { ComponentProps } from '../lib/DataLoader2';
import PanelHeading from 'react-bootstrap/lib/PanelHeading';


const $fmt  = new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency'});

type IncomingProps = {store: RestfulModelStore, ux_state: any, company_id: string};
type LoaderState   = {
  ProductSearch: string,
  productPage: number
};
type LoaderData    = {
  ProductSearch: string,
  products: ReifiedQueryResult<ProductFields>, 
  productPage: number
}

type ProductPaneState = {
  seq: number,
  product_overlay_id?: RecordId
}

class ProductPane extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>, ProductPaneState> {
  constructor(props: ComponentProps<IncomingProps,LoaderState,LoaderData>) {
    super(props);
    this.state = {
      seq: 0 //Maybe a stupid hack, monotonic counter with each user action
    };
  }

  handleInputChange(event: React.FormEvent<FormControl>) {
    const target = event.target;
    const value = (target as any).value;
    const name = (target as any).name;

    this.props.ux_state.set(name, value);
  }

  handleProductClick(product_id: RecordId) {
    this.setState({
      product_overlay_id: product_id,
      seq: this.state.seq + 1
    });
  }

  handlePageSelect(eventKey: number) {
    this.props.data_loader.setState({
      productPage: eventKey,
    });
  }

  render() {
    const state = this.state;
    const props = this.props;

    const search_box = <div>
      <span>Products</span>
      <FormGroup className='pull-right'>
        <FormControl type="text" placeholder="Search Products…" name="ProductSearch" value={props.ProductSearch} onChange={(e) => this.handleInputChange(e)}/>
      </FormGroup>
    </div>;

    const product_overlay = state.product_overlay_id ? (
      <ProductModal company_id={props.company_id} product_id={state.product_overlay_id} seq={state.seq} store={props.store} ux_state={props.ux_state}/>
    ) : "";

    const products = props.products;

    return <Grid>
      <Row>
        <Col md={4}>
          <Panel>
            <Panel.Heading>Upcoming Sales</Panel.Heading>
            <ScheduleTable store={props.store} company_id={props.company_id}/>
          </Panel>
        </Col>
        <Col md={8}>
          <Panel>
            <Panel.Heading>{search_box}</Panel.Heading>
            <Table striped condensed hover fill>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Markup</th>
                </tr>
              </thead>
              <tbody>
                {
                  products.map((p) => (
                    <tr key={p.id.toString()} data-key={p.id} style={{cursor: 'pointer'}} onClick={(e) => (this.handleProductClick(p.id))}>
                      {
                        p._found ? (<React.Fragment>
                          <td>{p.name}</td>
                          <td>{p.dollar_sell_price}</td>
                          <td>{p.category}</td>
                          <td>{p.markup}</td>
                        </React.Fragment>) : (<td colSpan={4}>Loading…</td>)
                      }
                    </tr>
                  ))
                }
                {
                  products._loaded && <tr style={{backgroundColor: "white", textAlign: 'center'}}><td colSpan={4}><UltimatePagination style={{margin: 0}} totalPages={(products.metadata|| []).total_pages} currentPage={(products.metadata || []).current_page} onChange={this.handlePageSelect.bind(this)}/></td></tr>
                }
                
              </tbody>
            </Table>
          </Panel>
          <Panel>
            <PanelHeading>Selection vs. Sales</PanelHeading>
            <SelectionGraph company_id={props.company_id} store={props.store}/>
          </Panel>

          {product_overlay}
        </Col>
      </Row>
    </Grid>;
  }
}

type ProductModalIncomingProps = {
  store: RestfulModelStore, 
  ux_state: any, 
  company_id: string,
  seq: number, //Forces redraws on new clicks
  product_id: RecordId
};
type ProductModalLoaderState   = {
};
type ProductModalLoaderData    = {
  product: FoundRecord<ProductFields>,
  scheduled_sales: ReifiedQueryResult<ScheduledSalesFields>
}

type ProductModalState = {
  closed_seq: number,
  start_date: string,
  end_date?: string,
  sale_price?: string,
  end_price?: string,
  allow_discounts: boolean,
  create_txn?: Txn
}

class _ProductModal extends React.PureComponent<ComponentProps<ProductModalIncomingProps,ProductModalLoaderState,ProductModalLoaderData>,ProductModalState> {
  constructor(props: ComponentProps<ProductModalIncomingProps,ProductModalLoaderState,ProductModalLoaderData>) {
    super(props);
    const start_date = new Date();
    start_date.setHours(0);
    start_date.setMinutes(0);
    start_date.setSeconds(0);
    const end_date   = new Date(start_date);
    end_date.setDate(end_date.getDate() + 7);

    this.state = { 
      closed_seq: 0, 
      //TODO: The form sould probably be factored into its own widget
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString(), 
      allow_discounts: false
    };

  }

  close() {
    this.setState({closed_seq: this.props.seq});
  }

  //TODO: Bailing on typechecking here because ::eyeroll::
  handleInputChange(e: any) {
    this.setState({[e.target.id]: e.target.value} as any);
  }

  handleCheckboxChange(e: any) {
    this.setState({[e.target.name]: e.target.checked} as any);
  }

  handleCalChange<K extends "start_date" | "end_date">(target: K, value: ProductModalState[K]) {
    var d = undefined;
    if(value) {
      d = new Date(value as string)
      d.setHours(0);
      d = d.toISOString();
    }
    this.setState({[target]: d} as Pick<ProductModalState,"start_date"|"end_date">);
  }

  handleDestroyClick(id: RecordId) {
    this.props.store.m(ScheduledSale).destroy(id);
  }

  createScheduledSale() {
    const state = this.state;
    const props = this.props;

    if(props.product === undefined) return; //Impossible

    const new_obj = {
      start: state.start_date,
      end: state.end_date,
      sale_price: this.salePrice(),
      end_price: this.endPrice(),
      greenbits_product_id: props.product.id as string, //TODO: but why?
      allow_discounts: state.allow_discounts
    };
    this.setState({
      create_txn: props.store.m(ScheduledSale).create(new_obj)
    });
  }

  salePrice() {
    const state = this.state;
    const props = this.props;
    if(state.sale_price !== undefined)
      return state.sale_price;
    else {
      return (0.9 * Number(props.product.dollar_sell_price)).toFixed(2);
    }
      
  }

  endPrice() {
    const state = this.state;
    const props = this.props;
    if(state.end_price != undefined)
      return state.end_price;
    else
      return Number.parseFloat(props.product.dollar_sell_price).toFixed(2);
  }



  render() {
    const props = this.props;
    const state = this.state;

    const current_price = () => (Number.parseFloat(props.product.dollar_sell_price).toFixed(2));
    const is_creating   = () => (this.state.create_txn && !this.props.store.txn_status(this.state.create_txn));

    return <Modal show={state.closed_seq < props.seq} onHide={() => (this.close())}>
      <Modal.Header closeButton>
        <Modal.Title>{props.product.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid fluid style={{padding: 0}}>
          <Row><Col md={12}><h4>Schedule Price Change</h4></Col></Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Start Date</ControlLabel>
                <DatePicker value={state.start_date} onChange={(v: any) => (this.handleCalChange("start_date", v))} showClearButton={false} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>End Date</ControlLabel>
                <DatePicker value={state.end_date} onChange={(v:any) => (this.handleCalChange("end_date", v))}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup controlId="sale_price">
                <ControlLabel>Sale Price</ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>$</InputGroup.Addon>
                  <FormControl type="text" value={this.salePrice()} placeholder="Enter text" onChange={(e) => (this.handleInputChange(e))} />
                </InputGroup>
                <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup controlId="end_price">
                <ControlLabel>Regular Price</ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>$</InputGroup.Addon>
                  <FormControl type="text" value={this.endPrice()} placeholder="Enter text" onChange={(e) => (this.handleInputChange(e))} />
                </InputGroup>
                <FormControl.Feedback />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup controlId="allow_discounts">
                 <Checkbox name='allow_discounts' checked={state.allow_discounts} onChange={(e) => (this.handleCheckboxChange(e))}>Allow discounts during sale</Checkbox>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
              On <b>{(new Date(state.start_date)).toLocaleDateString()}</b>, JointLeaf will change the 
              price of <b>{props.product.name}</b> from <b>${current_price()}</b> to <b>${this.salePrice()}</b>.&nbsp;
              {
                (state.end_date ? <span>
                  On <b>{(new Date(state.end_date)).toLocaleDateString()}</b>, JointLeaf will change the price to <b>${this.endPrice()}</b>.    
                </span> : "")
              }
              </p>
              <p><Button bsStyle="primary" disabled={is_creating()} onClick={(e) => (this.createScheduledSale())}>{is_creating() ? "Creating Scheduled Sale…" : "Schedule"}</Button></p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h4>Scheduled Price Changes</h4>
              <Table>
                <thead>
                  <tr><th>Start</th><th>Sale Price</th><th>End</th><th>End Price</th></tr>
                </thead>
                <tbody>
                {
                  props.scheduled_sales.map((ss) => (
                    (ss._found) ?
                    <tr key={ss.id.toString()} data-key={ss.id} className={"scheduled-sale-item"}>
                      <td>{new Date(ss.start).toLocaleDateString()}</td>
                      <td>{$fmt.format(Number(ss.sale_price))}</td>
                      <td>{ss.end && new Date(ss.end).toLocaleDateString()}</td><td>{$fmt.format(Number(ss.end_price))}</td>
                      <td>{
                        ss._loading ? <Glyphicon glyph="refresh" className={"glyphicon-refresh-animate"}/> :
                        <Glyphicon glyph="remove" className={"remove-button"} onClick={(e) => (this.handleDestroyClick(ss.id))}/>
                      }</td>
                    </tr> : <tr><td colSpan={4}>(Sale Data Not Found)</td></tr>
                  ))
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
      <Modal.Footer>
        Footer
      </Modal.Footer>
    </Modal>;
  }
}

const ProductModal = LoaderWrapper(_ProductModal, ['store', 'ux_state'], (props, state, dataLoader) => {
  const product         = props.store.m(Product).fetch(props.product_id) as FoundRecord<ProductFields>; // It shouldn't be possible to get this far without the product being definitively loaded
  //const scheduled_sales = props.store.queryForScheduledSales('',{company_id: props.company_id, product_id: props.product_id});
  const scheduled_sales = props.store.m(ScheduledSale).queryFor('',{company_id: props.company_id, product_id: props.product_id});

  return {
    product: product,
    scheduled_sales: scheduled_sales

  }
});

type ScheduleTableIncomingProps = {
  store: RestfulModelStore, 
  company_id: string,
};
type ScheduleTableLoaderState   = {
};
type ScheduleTableLoaderData    = {
  scheduled_sales: ReifiedQueryResult<ScheduledSalesFields>
}

class _ScheduleTable extends React.PureComponent<ComponentProps<ScheduleTableIncomingProps,ScheduleTableLoaderState,ScheduleTableLoaderData>> {
  render() {
    const props = this.props;

    type Tuple = {type: "s"|"e", date: string, scheduled_sale: FoundRecord<ScheduledSalesFields>};

    const scheduled_tuples : Tuple[] = [];
    props.scheduled_sales.forEach((ss) => (ss._found && scheduled_tuples.push({type: 's',date: new Date(ss.start).toLocaleDateString(), scheduled_sale: ss})));
    props.scheduled_sales.forEach((ss) => (ss._found && ss.end && scheduled_tuples.push({type: 'e', date: new Date(ss.end).toLocaleDateString(), scheduled_sale: ss})));

    const grouped = scheduled_tuples.reduce((hash, ss) => {
      (hash[ss.date] = hash[ss.date] || []).push(ss);
      return hash;
    }, {} as {[date: string]: Tuple[]});

    const by_day = Object.keys(grouped).map((day) => ([day, grouped[day]] as [string, Tuple[]])).
      sort((a,b) => (new Date(a[0]).valueOf() - new Date(b[0]).valueOf()));


    return <Grid fluid style={{padding: 0}}>
      {
        by_day.map((day_tuple) => (
          <Row key={day_tuple[0]}>
            <Col md={12}>
              <h3>{day_tuple[0]}</h3>
              {day_tuple[1].map((ss_tuple) => {
                const product = props.store.m(Product).fetch(ss_tuple.scheduled_sale.greenbits_product_id);
                return <Row key={ss_tuple.type + "-" + ss_tuple.scheduled_sale.greenbits_product_id}>
                    <Col md={8}>{product._found ? product.name : <span style={{fontStyle: 'italic'}}>(Loading…)</span>}</Col>
                    <Col md={4}>
                      <span style={{color: ss_tuple.type == 'e' ? 'red' : undefined, "fontSize": 'smaller', "textDecoration": "line-through", paddingRight: '0.5em'}}>{$fmt.format(Number(ss_tuple.scheduled_sale[(ss_tuple.type == 'e') ? 'sale_price' : 'end_price']))}</span>
                      <span style={{color: ss_tuple.type == 's' ? 'red' : undefined, "fontWeight": 'bolder', fontSize: '125%'}}>{$fmt.format(Number(ss_tuple.scheduled_sale[(ss_tuple.type == 's') ? 'sale_price' : 'end_price']))}</span>
                    </Col>
                  </Row>;
              })}
            </Col>
          </Row>
        ))
      }
    </Grid>;
  }
}

const ScheduleTable = LoaderWrapper(_ScheduleTable, ['store'], (props, state, dataLoader) => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  const scheduled_sales = props.store.m(ScheduledSale).queryFor('',{company_id: props.company_id, after: today.toString() });
  //The data is discarded, but the side effect is that we'll load these products during render, and accessing them here 
  //triggers sequencetracking, meaning that we'll repain when they change
  const products        = scheduled_sales.map((ss) => (
    ss._found ? props.store.m(Product).fetch(ss.greenbits_product_id) : undefined
  ));

  return {
    scheduled_sales: scheduled_sales
  };
});


export default LoaderWrapper(ProductPane, ['store', 'ux_state'], (props, state, dataLoader) => {
  const company_id  = props.company_id;
  const search_text = props.ux_state.get('ProductSearch') || '';
  //Reset to page one if the search text changes
  const productPage = (search_text.length > 0 && state.ProductSearch != search_text) ? 1 : ((state && state.productPage) || 1);
  const products = company_id ? props.store.m(Product).queryFor('',{company_id: company_id, search_text: search_text, page: productPage}) : props.store.emptyLoadingQuery<ProductFields>();

  return {
    ProductSearch: search_text,
    products: products, 
    productPage: productPage
  };
});

