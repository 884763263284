"use strict";

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Nav, NavItem, Glyphicon, Grid, Row, Col, Panel, Popover, OverlayTrigger, InputGroup, Button, ButtonGroup } from 'react-bootstrap';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import classNames from 'classnames';

import LoaderWrapper, { ComponentProps } from '../../lib/DataLoader2';
import AllProductsDetails from './AllProductsDetails';
import InventoryTargetDetails from './InventoryTargetDetails';
import InventoryTarget from './InventoryTarget';
import RestfulModelStore, { RecordId, ReifiedQueryResult, FoundRecord, NewRecord, NewRecordId } from '../../lib/RestfulModelStore';
import { InventoryTargetFields, InventoryTarget as InventoryTargetModel } from '../../lib/Models';

type IncomingProps = {
  store: RestfulModelStore, 
  ux_state?: any,
  entity_id: number,
  vendor_id: number,
  company_id?: string //TODO: Get rid of this
};
type LoaderState   = {
  new_target_id?: NewRecordId
};
type ViewSummary = {source_vendor_id: number, date: string, contribution_margin: number, transactions: number, gross_sales: number};
type LoaderData    = {
  inventory_targets: ReifiedQueryResult<InventoryTargetFields>
  new_target_id?: NewRecordId
}
type ViewState = {
  focused_inventory_target_id?: RecordId<number>
}

class VendorTab extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>,ViewState> {
  onNewTargetClick() {
    const props = this.props;
    props.data_loader.setState({
      new_target_id: props.store.new<InventoryTargetFields>({
        entity_id: props.entity_id,
        vendor_id: props.vendor_id
      }).id
    });
  }

  onFocusTarget(inventory_target_id: RecordId<number>) {
    this.setState({
      focused_inventory_target_id: inventory_target_id
    });
  }

  onBlurTarget() {
    this.setState({
      focused_inventory_target_id: undefined
    });
  }

  render() {
    const props = this.props;
    const fitid = this.state && this.state.focused_inventory_target_id;

    return <Grid fluid style={{padding: 0}} className="vendor-tab">
      <Row>
        <Col md={4}>Relative Markup</Col><Col md={4}>Relative Contribution Margin</Col><Col md={4}>Relative Volume</Col>
      </Row>
      <Row style={{marginTop: '1em'}}>
        <Col md={12} style={{padding: "0 2em"}}><hr/></Col>
        <Col md={5}>
          {
            <Button onClick={(e) => (this.onBlurTarget())} active={!fitid} style={{float: "none", width: "100%", marginBottom: "10px"}}>All Products</Button>
          }
          {
            props.inventory_targets.map((it) => (
              <span key={it.id.toString() } onClick={() => (this.onFocusTarget(it.id))}><InventoryTarget store={props.store} entity_id={props.entity_id} id={it.id} focused={it.id == fitid}/></span>
            ))
          }
          {
            props.new_target_id ? 
              <InventoryTarget store={props.store} entity_id={props.entity_id} id={props.new_target_id} focused={props.new_target_id == fitid}/>
            : null
          }
          {
            <div style={{textAlign: 'center'}}>
              <ButtonGroup>
                <Button onClick={(e) => (this.onNewTargetClick())} disabled={!!props.new_target_id}>New Target</Button>
              </ButtonGroup>
            </div>
          }
        </Col>
        <Col md={7}>
          {
            fitid ? (
              <InventoryTargetDetails store={props.store} inventory_target_id={fitid}/>
            ) : (
              <AllProductsDetails store={props.store} entity_id={props.entity_id} source_vendor_id={props.vendor_id}/>
            )
          }
        </Col>
      </Row>
    </Grid>;
  }
}

export default LoaderWrapper(VendorTab, ['store'], (props, state, dataLoader) => {
  const store = props.store;

  let nt;
  if(state && state.new_target_id) {
    nt = store.m(InventoryTargetModel).fetch(state.new_target_id);
  }
  return {
    inventory_targets: store.m(InventoryTargetModel).queryFor("",{entity_id: props.entity_id, vendor_id: props.vendor_id}),
    new_target_id: (nt && nt._new) ? (nt as NewRecord<InventoryTargetFields>).id : undefined //Clear out the new target if it's been saved
  }
});
