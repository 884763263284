import React, {Ref} from 'react';

import { Button, Col, Glyphicon, Grid, Row, OverlayTrigger, Panel, Popover, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, TypeaheadLabelKey, StringPropertyNames } from 'react-bootstrap-typeahead';

import classNames from 'classnames';

import LoaderWrapper, {ComponentProps} from '../../lib/DataLoader2';
import RestfulModelStore, { ModelDefinition, Identifiable, TxnResult, RecordId, ModelRecord, ReifiedQueryResult, FoundRecord, NewRecord } from '../../lib/RestfulModelStore';
import { FlowerType, PackageSize, ProductType, InventoryTarget as InventoryTargetModel, InventoryTargetFields } from '../../lib/Models';
import { optionalCallExpression } from '@babel/types';


type Props = {
  store: RestfulModelStore,
  entity_id: number | string,
  // company_id: number| string,  
  id: RecordId<number>,
  focused: boolean
}

type Data = {
  inventory_target: ModelRecord<InventoryTargetFields> | NewRecord<InventoryTargetFields>,
  txn_status?: TxnResult
}

type RenderState = {
  modifications?: Partial<InventoryTargetFields>
}

type LoaderState = {
  save_txn: any
}

type RenderProps = ComponentProps<Props,LoaderState,Data>;

class _InventoryTarget extends React.PureComponent<RenderProps, RenderState> {
  constructor(props: RenderProps) {
    super(props)
    this.state = {};
  }

  doModification(name_or_hash: string | object, value: any) {
    if(typeof(name_or_hash) === 'object') {
      this.setState({
        modifications: {...(this.state.modifications || {}), ...name_or_hash }
      });
    } else {
      this.setState({
        modifications: {...(this.state.modifications || {}), ...{[name_or_hash]: value}}
      });  
    }
  }

  doSave() {
    const props = this.props;
    if(!this.state.modifications) return;

    props.data_loader.setState({
      save_txn: props.store.m(InventoryTargetModel).patch(props.id, this.state.modifications)
    });
    this.setState({modifications: undefined});
  }

  doDelete() {
    const props = this.props;
    props.data_loader.setState({
      save_txn: props.store.m(InventoryTargetModel).destroy(props.id)
    });
  }

  //Returns either the value out of the model, or else out of the unsaved modifications
  read_field<K extends keyof InventoryTargetFields>(name: K) : Partial<InventoryTargetFields>[K] {
    const state = this.state;
    const props = this.props;
    const it = props.inventory_target;
    if(!it._found) throw "Impossible";

    if(typeof state.modifications !== "undefined" && typeof state.modifications[name] !== "undefined") {
      return state.modifications[name] as InventoryTargetFields[K];
    } else {
      return it[name];
    }
  }

  is_dirty() { return !!this.state.modifications; }
  is_saving() {
    const props      = this.props;
    const save_txn   = props.save_txn;
    const txn_status = save_txn && props.txn_status;
    return save_txn && !txn_status;
  }

  save_btn() { 
    const props = this.props;
    if(this.is_dirty() && !this.is_saving()) {
      return <Button bsStyle="primary" type='submit' onClick={(e) => (this.doSave())}>Save</Button>;
    } else {
      return null;
    }
  }

  save_status() {
    const props = this.props;
    if(props.save_txn) {
      const txn_status = props.txn_status;
      if(txn_status) {
        if(txn_status.status === 'succeeded') {
          return "saved";
        } else {
          return "error saving";
        }
      } else {
        return "saving…";
      }
    } else if(this.is_dirty()) {
      return "unsaved";
    } else {
      return null;
    }
  }

  render() {
    const props = this.props;
    const doMod = this.doModification.bind(this);
    const inventory_target = props.inventory_target;

    if(inventory_target._loading || !inventory_target._found) return "";

    const status = inventory_target.failed_target ? "danger"  :
                   inventory_target.on_target     ? "success" :
                   "warning";
    return <Panel bsStyle={status} className={classNames("inventory-target",{focused: props.focused})}>
      <Panel.Body>
        <Grid fluid style={{padding: 0}}>
          <Row>
            <Col md={12}>
              Maintain at least <NumberSelector name='days_supply' value={this.read_field('days_supply')} doModify={doMod}> days'</NumberSelector> supply 
              in least <NumberSelector name='strain_count' value={this.read_field('strain_count')} doModify={doMod}>  strains </NumberSelector>
              of <ProductTypeSelector name='product_type_id' value={this.read_field('product_type_id')} store={props.store} doModify={doMod} search_args={{entity_id: props.entity_id}}>Products</ProductTypeSelector> made 
              with <FlowerTypeSelector name='flower_type_id' value={this.read_field('flower_type_id')} store={props.store} doModify={doMod}> Flower</FlowerTypeSelector>&nbsp;in <SizesSelector 
                name='package_size_ids' value={this.read_field('package_size_ids')} disabled={!this.read_field('product_type_id')} store={props.store} search_args={{product_type_id: this.read_field('product_type_id')}} doModify={doMod}>packages</SizesSelector>.</Col>
            <Col md={12}>
              
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{textAlign: "right"}}>
              {this.save_status()}
              {this.save_btn()}
              <TwoStepButton doDelete={() => (this.doDelete())}/>
            </Col>
          </Row>
        </Grid>
      </Panel.Body>
    </Panel>;
  }
}

const InventoryTarget = LoaderWrapper(_InventoryTarget, ['store'], 
  (props: Props, state: LoaderState, dataLoader: any) : Data => {

    let txn = (state || {}).save_txn;
    return {
      inventory_target: props.store.m(InventoryTargetModel).fetch(props.id),
      txn_status: txn && props.store.txn_status(txn)
    };
  }
);

export default InventoryTarget;

// A search-as-you-type on 
const FlowerTypeSelector  = SearchAsYouTypeSelectorBuilder(FlowerType,   "name", false, "Select Flower Type");
const SizesSelector       = SearchAsYouTypeSelectorBuilder(PackageSize, "display_text", true, "Select Sizes");
const ProductTypeSelector = SearchAsYouTypeSelectorBuilder(ProductType, "name", false, "Select Product Type");


function SearchAsYouTypeSelectorBuilder<T extends Identifiable>(model_definition: ModelDefinition<T>, display_field: TypeaheadLabelKey<FoundRecord<T>>&(keyof FoundRecord<T>), multi_select: boolean, select_text: string) {
  type LoaderProps = {
    store: RestfulModelStore,
    value?: T["id"] | T["id"][],
    search_args?: {
      [index: string]: any
    },
    disabled?: boolean,
    name: string,
    doModify: (name: string, value: T["id"][] | T["id"] | undefined) => void  
  };

  type LoaderState = {
    search_text: string,
    editing: boolean
  };

  //TODO: These types are ad-hoc from the storage lib
  type LoaderData = {
    results?: ReifiedQueryResult<T,any,ModelRecord<T>>,
    records?: ReifiedQueryResult<T,any,ModelRecord<T>>
  };

  const _SelectorClass = class Selector extends React.PureComponent<ComponentProps<LoaderProps,LoaderState,LoaderData>> {
    typeahead_field: any;

    handleFocus(e: Event | React.SyntheticEvent) {
      e.preventDefault();
      if(!this.props.disabled) this.props.data_loader.setState({editing: true});
    }

    handleSearch(q : string) {
      this.props.data_loader.setState({
        search_text: q
      });
    }

    handleBlur(e: Event) {
      if(!multi_select) 
        this.props.data_loader.setState({editing: false});
    }

    componentDidUpdate() {
      if(this.props.editing && this.typeahead_field) this.typeahead_field.getInstance().focus();
    }

    handleChange(selected: Identifiable[]) {
      const props = this.props;
      if(multi_select) {
        props.doModify(props.name, selected.map((s) => (s.id)));
      } else {
        if(selected[0]) {
          props.doModify(props.name, selected[0].id);

          //Pop out of editing mode once selected
          this.props.data_loader.setState({editing: false});
        } else {
          props.doModify(props.name, undefined);
        }

      }
    }

    handleOk(ev: React.MouseEvent<Button>) {
      this.props.data_loader.setState({editing: false});
    }

    render() {
      const props = this.props;

      if(props.editing) {

        return <React.Fragment>
          <div style={{display: "inline-block", verticalAlign: 'middle'}}>
            <InputGroup>
              <AsyncTypeahead
                isLoading={!!props.results && props.results._loading}
                labelKey={display_field}
                minLength={0}
                ref={(r) => (this.typeahead_field = r)}

                onSearch={(q) => (this.handleSearch(q))}
                onBlur={(e) => (this.handleBlur(e))}
                onChange={(s) => (this.handleChange(s))}
                
                placeholder={select_text}
                options={props.results || []}
                selectHintOnEnter={true}
                multiple={multi_select}
                selected={multi_select ? props.records : []}
                // className={"adlib-inline-field"}
              />
              {multi_select ? <InputGroup.Button>
                <Button bsStyle="primary" type="submit" onClick={(e) => (this.handleOk(e))}>
                  <Glyphicon glyph='ok'/>
                </Button>
              </InputGroup.Button> : undefined }
            </InputGroup>
          </div>
          {props.children}
        </React.Fragment>;
      } else if(!props.records) {
        return <a className={classNames("adlib-select", {disabled: props.disabled})} href="#" onClick={(e) => this.handleFocus(e)}>{select_text}</a>;
      } else if(props.records._loading) {
        return <span>[…]</span>;
      } else {
        const selected_value = <span>{props.records.map((r) => (r._found ? r[display_field] : "…")).join(', ')}</span>;
        return <a className={"adlib-select"} href="#" onClick={(e) => this.handleFocus(e)}>{selected_value} {props.children}</a>;
      }
    }
  }

  return LoaderWrapper(_SelectorClass, ['store'], 
    (props: LoaderProps, state: LoaderState, dataLoader) : LoaderData => {
      const store    = props.store;
      state = state || {};

      const search_text = state.search_text;
      const records = (typeof(props.value) !== 'undefined') ? 
        store.m(model_definition).fetchMany((props.value instanceof Array) ? props.value : [props.value]) :
        undefined
      const ret = {
        records: records,
        results: search_text ? 
        (store.m(model_definition).queryFor('',{...(props.search_args || {}), search_text: search_text})) :
        (store.m(model_definition).queryFor('', props.search_args || {}))
      };

      return ret;
    }
  );
}


type NumberSelectorProps = {
  value?: number,
  name: string,
  doModify: (name: string, value: number | undefined) => void
};
type NumberSelectorState = {
  value: number | undefined,
  editing: boolean
}
class NumberSelector extends React.PureComponent<NumberSelectorProps, NumberSelectorState> {
  state = {
    editing: false,
    value: undefined
  };

  handleFocus(e: React.SyntheticEvent) {
    e.preventDefault();
    //the 'value' is only used while editing, otherwise it's rendered straight from props
    this.setState({editing: true, value: this.props.value});
  }
  handleBlur(e: React.SyntheticEvent) {
    this.setState({editing: false});
    this.props.doModify(this.props.name, this.state.value);
  }
  handleChange(e: React.SyntheticEvent) {
    this.setState({value: (e.target as any).value});
  }

  render() {
    const props = this.props;
    const state = this.state;

    const overlay = <Popover title="Popover bottom" id={props.name + "-explainer"}>
      
    </Popover>;

    return state.editing ? 
      (<React.Fragment><input style={{width: '3em'}} value={state.value} onBlur={(e) => this.handleBlur(e)} onChange={(e) => this.handleChange(e)}/>{props.children}</React.Fragment>) :
      (<a className={"adlib-select"} href="#" onClick={(e) => this.handleFocus(e)}>{props.value}{props.children}</a>)
    ;
  }
}

type TwoStepDeleteProps = {
  doDelete: () => void
};

type TwoStepDeleteState = {
  expanded: boolean
};

class TwoStepButton extends React.PureComponent<TwoStepDeleteProps, TwoStepDeleteState> {
  state = { expanded: false };

  toggle() {
    this.setState({expanded: !this.state.expanded});
  }
  
  render() {
    return <OverlayTrigger
      trigger={['click']}
      placement="bottom"
      rootClose={true}
      overlay={
      <Popover id='low-popover'>
        <div>Are you sure?</div>
        <Button bsStyle="danger" onClick={(e) => (this.props.doDelete())}>Delete</Button>
      </Popover>
      }>
        <Glyphicon glyph='trash' onClick={(e) => (this.toggle())}/>
    </OverlayTrigger>;
  }
}