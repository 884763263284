import React, {Fragment} from 'react';

import { Nav, NavItem, NavDropdown, MenuItem, Navbar, Glyphicon, ProgressBar } from 'react-bootstrap';

import LoaderWrapper from '../lib/DataLoader2';

import './Nav/styles.scss';
import RestfulModelStore, { ModelRecord, ReifiedQueryResult } from '../lib/RestfulModelStore';
import { ComponentProps } from '../lib/DataLoader2';
import {EntityFields, Entity, Session, UserFields, CompanyFields, SessionFields, User, Company} from '../lib/Models';

type IncomingProps = {store: RestfulModelStore, ux_state: any, company_id: string};
type LoaderState   = {};
type LoaderData    = {
  entity?: ModelRecord<EntityFields>,
  user?: ModelRecord<UserFields>,
  company: ModelRecord<CompanyFields>,
  companies: ReifiedQueryResult<CompanyFields>,
  session: ModelRecord<SessionFields>,
  page: string
}

class AppNav extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>> {
  timer? : number = undefined;

  componentDidMount() {
    //The timer is used to refresh the entity object to render, e.g., the loading bar
    let timer = window.setInterval(() => (this.tick()), 60000);
    this.timer = timer;
  }

  componentWillUnmount() {
    window.clearInterval(this.timer);
  }

  tick() {
    const props = this.props;
    if(props.entity && props.entity._found)
      props.store.m(Entity).fetch(props.entity.id, true);
  }

  handleSelect(key: string) {
    this.props.ux_state.set('page',key);
  }

  handleCompanySelect(key: string) {
    this.props.ux_state.set('company_id', key);
  }

  handleAccountSelect(key: string) {
    switch(key) {
      case "logout": this.props.store.s(Session).destroy();
    }
  }

  render() {
    const props = this.props;
    const user  = props.user;
    const companies = props.companies;
    const company = props.company;
    const session = props.session;

    let companies_dropdown, companies_menu;
    if(companies._loading)
      companies_menu = <MenuItem title="Loading…" disabled></MenuItem>;
    else
      companies_menu = companies.map((c, idx) => ((c._found === true) ? <MenuItem key={c.id.toString()} eventKey={c.id}>{c.name}</MenuItem> : "(Company Not Found)"));

    companies_dropdown = <NavDropdown onSelect={(key:any) => (this.handleCompanySelect(key))} id='company_selector' title={company._loading || !company._found ? "Loading…" : company.name}>
      {companies_menu}
    </NavDropdown>;

    const user_dropdown = <NavDropdown onSelect={(key:any) => (this.handleAccountSelect(key))} id="user_menu" title={<span><Glyphicon glyph="user"/></span>}> {
      (user && user._loaded) ? (
        [
          <MenuItem disabled key="name">{user._found ? user.name : "Loading…"}</MenuItem>,
          <MenuItem divider key="divider"></MenuItem>,
          <MenuItem key="logout" eventKey='logout'>Logout</MenuItem>
        ]
      ) : (
        <MenuItem disabled>Loading…</MenuItem>
      )
    }</NavDropdown>;

    return (
      <Fragment>
        <Navbar>
          <Navbar.Header>
            <Navbar.Brand>
              JL Master Kush
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav activeKey={props.page} onSelect={(key) => (this.handleSelect(key as unknown as string))}>
              <NavItem eventKey="dashboard">Dashboard</NavItem>
              <NavItem eventKey="products">Products &amp; Pricing</NavItem>
              <NavItem eventKey="vendors">Vendors &amp; Orders</NavItem>
              <NavItem eventKey="inventory" disabled>Inventory</NavItem>
            </Nav>
            <Nav pullRight>
              {companies_dropdown}
              {user_dropdown}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div style={{marginBottom: "20px"}}>{(() => {
          let entity = props.entity;
          if(entity && entity._found && entity.job_processing && entity.job_progress) {
            let job_progress = entity.job_progress;
            return <ProgressBar active 
              now={job_progress[1] - job_progress[0]} 
              max={job_progress[1]} 
              label={`${entity.job_processing} (${job_progress[1] - job_progress[0]}/${job_progress[1]})`}/>;
          } else
            return "";
        })()}</div>
      </Fragment>
    );
  }
}

export default LoaderWrapper(AppNav, ['store', 'ux_state'], (props, state, dataLoader) => {
  const ret = {};

  //Data store generation
  const store    = props.store;

  const session   = props.store.s(Session).fetch();
  const user      = session._found ? store.m(User).fetch(session.current_user_id) : undefined;
  const company   = store.m(Company).fetch(props.company_id);
  const companies = user && user._found ? store.m(Company).fetchMany(user.accessible_company_ids) : store.emptyLoadingQuery<CompanyFields>();
  const entity    = (company._loaded && company._found) ? store.m(Entity).fetch(company.entity_id) : undefined;


  //ux_state generation
  const ux_state = props.ux_state;

  return {
    session: session,
    user: user,
    company: company,
    companies: companies,
    entity: entity,
    page: ux_state.get('page') || 'dashboard'
  };
});
