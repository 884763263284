import React from 'react';

import { Col, Glyphicon, Grid, Popover, OverlayTrigger, Row } from 'react-bootstrap';

import LoaderWrapper, {ComponentProps, DataLoader} from '../../lib/DataLoader2';
import RestfulModelStore from '../../lib/RestfulModelStore';
import {ProductSalesVelocity, Product, FlowerType, PackageSize} from '../../lib/Models';

import classNames from 'classnames';
import ProductSalesTable, {SalesTuple, InventoryTargetDetailsData, sortsBy} from './ProductSalesTable';

const numberFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 });
const intFormatter    = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 });

interface AllProductsProps {
  store: RestfulModelStore;
  entity_id: number | string;
  source_vendor_id: number | string;
};


const AllProductsDetails = LoaderWrapper(ProductSalesTable, ['store'],
  (props: AllProductsProps, state: {}, dataLoader: any) : InventoryTargetDetailsData => {
    const store = props.store;
    const yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1); //So gross

    const product_velocities = store.m(ProductSalesVelocity).queryFor(null,{
      entity_id: props.entity_id,
      date: yesterday.toDateString(),
      source_vendor_id: props.source_vendor_id
    });

    const sales_tuples = !product_velocities._loaded ? [] : (
      product_velocities.map((psv) => {
        if(psv._found != true) return null;

        const product = store.m(Product).fetch(psv.product_id);
        return {
          product: product,
          sales_velocity: psv,
          category: product._found ? product.category : null,
          velocity: (psv && psv.velocity) || 0,
          remaining: product._found ? Number(product.quantity) : Number.MAX_SAFE_INTEGER,
          exhaust_date: (psv && psv.exhaust_date) ? new Date(psv.exhaust_date).valueOf() : Number.MAX_SAFE_INTEGER,
          name: product._found && product.name
        } as SalesTuple
      })
    );

    const product_groups = sales_tuples.reduce((groups, st) => {
      if(st === null) return groups;

      if(!st.product._loaded) return groups; //Skip unloaded products
      const product = st.product;
      
      const flower_type  = (typeof(product.flower_type_id) === 'number') ? store.m(FlowerType).fetch(product.flower_type_id) : undefined;
      const package_size = store.m(PackageSize).fetch(product.package_size_id);
      const name = `${product.category} / ${flower_type && flower_type._found ? flower_type.name : "(No Flower Type)"} / ${package_size && package_size._found ? package_size.display_text : ""}`;

      const group = groups[name] = (groups[name] || {
        sales_tuples: [], 
        name: name, 
        gram_weight: package_size._found && package_size.gram_weight,
        category: product.category,
        flower_type: flower_type && flower_type._found ? flower_type.name : "(No Flower Type)"
      });
      
      group.sales_tuples.push(st);
      return groups;
    }, {} as ({[key: string]: {sales_tuples: SalesTuple[], name: string, gram_weight: number, category: string, flower_type: string}}));

    return {
      product_groups: Object.values(product_groups).sort(sortsBy([{property: 'category'},{property: 'flower_type'},{property: 'gram_weight'}]))
    };
  }
);

export default AllProductsDetails;