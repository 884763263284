import React from 'react';

import { Col, Glyphicon, Grid, Popover, OverlayTrigger, Row } from 'react-bootstrap';

import LoaderWrapper, {ComponentProps, DataLoader} from '../../lib/DataLoader2';
import classNames from 'classnames';
import ProductSalesTable, {SalesTuple, InventoryTargetDetailsData} from './ProductSalesTable';
import RestfulModelStore, { RecordId } from '../../lib/RestfulModelStore';
import { InventoryTarget, FlowerType, PackageSize, Product, ProductSalesVelocity } from '../../lib/Models';

const numberFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 });
const intFormatter    = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 });

interface InventoryTargetDetailsProps {
  store: RestfulModelStore;
  inventory_target_id: RecordId<number>;
};


const InventoryTargetDetails = LoaderWrapper(ProductSalesTable, ['store'],
  (props: InventoryTargetDetailsProps, state: {}, dataLoader: any) : InventoryTargetDetailsData => {
    const store = props.store;

    const inventory_target = store.m(InventoryTarget).fetch(props.inventory_target_id);

    const product_groups = (!inventory_target._loaded || !inventory_target._found) ? [] : (
      (inventory_target.target_groups as Array<any>).map((tg) => {
        const flower_type  = store.m(FlowerType).fetch(inventory_target.flower_type_id);
        const package_size = store.m(PackageSize).fetch(tg.package_size_id);

        const sales_tuples : SalesTuple[] = [];
        tg.target_tuples.forEach((target_tuple: any) => {
          const product = store.m(Product).fetch(target_tuple.product_id);
          const psv_id = target_tuple.product_sales_velocity_id;
          const psv = psv_id && store.m(ProductSalesVelocity).fetch(psv_id);

          if(!product._found || (psv && !psv._found)) return;
          sales_tuples.push({
            product: product,
            sales_velocity: psv,
            category: product.category,
            velocity: (psv && psv.velocity) || 0,
            remaining: product._found ? Number(product.quantity) : Number.MAX_SAFE_INTEGER,
            exhaust_date: (psv && psv.exhaust_date) ? new Date(psv.exhaust_date).valueOf() : Number.MAX_SAFE_INTEGER,
            name: product.name      
          });
        });

        return {
          name: `${flower_type._found ? flower_type.name : "(Loading Flower Type…)"} / ${package_size._found? package_size.display_text : ("Loading Package Size…")}`,
          sales_tuples: sales_tuples
        };
      })
    );

    return {
      product_groups: product_groups
    };
  }
);



export default InventoryTargetDetails;