/*import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/

import React from 'react'
import ReactDOM from 'react-dom'

import RestfulModelStore from './analytics/lib/RestfulModelStore';
import MemoryStore       from './analytics/lib/MemoryStore';
import Main from './analytics';

import axios from 'axios';
import RateLimiter from './analytics/lib/RateLimiter';

declare global {
  interface Window {
    axios: any;
    Store: any;
    UXState: any;
  }
}


document.addEventListener('DOMContentLoaded', () => {
  const api_origin = process.env.REACT_APP_API_ORIGIN;
  const AxiosClient = axios.create({
    baseURL: api_origin, //trailing slash is redundant with URL formation
    withCredentials: true
  });
  
  
  window.axios = AxiosClient;

  // Handle assigning the CSRF token
  AxiosClient.interceptors.request.use(function(config) {
    let csrf_meta = document.head.querySelector('meta[name=csrf-token]');
    config.headers = config.headers || {};  
    config.headers['X-CSRF-Token'] = csrf_meta && csrf_meta.getAttribute('content');
    return config;
  });
  AxiosClient.interceptors.response.use(function(response) {
    const csrf_token = response.headers['x-csrf-token'];
    if(csrf_token) {
      const csrf_meta = document.head.querySelector('meta[name=csrf-token]');
      csrf_meta && csrf_meta.setAttribute('content', csrf_token);
    }
    return response;
  }, (error) => {
    if(error.response) {
      const csrf_token = error.response.headers['x-csrf-token'];
      if(csrf_token) {
        const csrf_meta = document.head.querySelector('meta[name=csrf-token]');
        csrf_meta && csrf_meta.setAttribute('content', csrf_token);
      }  
    }
    return Promise.reject(error);
  });

  
  //window.Store For debugging and stuff
  const Store = window.Store = new RestfulModelStore(AxiosClient);
  const ux_state = new MemoryStore({
    page: 'dashboard',
    needsLogin: undefined
  });
  window.UXState = ux_state;

  ReactDOM.render(
    // <RateTester/>,
    <Main store={Store} ux_state={ux_state}/>,

    document.body.appendChild(document.createElement('div')),
  )
});

class RateTester extends React.Component<{},{
  currentRps?: number,
  fails?: number,
  successes?: number,
  queues?: number
}> {
  private ival: number | undefined;
  private rateLimiter = new RateLimiter({});
  private updateIval: number | undefined;

  constructor(props: {}) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.ival = window.setInterval(() => {
      this.rateLimiter.execute(async () => {
        if(Math.random() > 0.99) {
          throw new Error('Random Error');
        }
        return 42;
      }).catch((e) => (console.log("SWALLOWED")));
    },100);

    this.updateIval = window.setInterval(() => (
      this.setState({
        currentRps: this.rateLimiter.currentRps,
        fails: this.rateLimiter.fails,
        successes: this.rateLimiter.successes,
        queues: this.rateLimiter.queues
      })
    ), 1000);
  }

  componentWillUnmount() {
    if(this.ival) {
      window.clearInterval(this.ival);
      this.ival = undefined;
    }
    if(this.updateIval) {
      window.clearInterval(this.updateIval);
      this.updateIval = undefined;
    }
  }

  render() {
    return <div>
      <div><span>Current RPS</span><span>{this.state.currentRps}</span></div>
      <div><span>Fails</span><span>{this.state.fails}</span></div>
      <div><span>Successes</span><span>{this.state.successes}</span></div>
      <div><span>Queues</span><span>{this.state.queues}</span></div>
    </div>
  }
}