import React from 'react';

import { Col, Glyphicon, Grid, Popover, OverlayTrigger, Row } from 'react-bootstrap';

import {ProductFields} from '../../lib/Models';
import classNames from 'classnames';
import { ModelRecord, FoundRecord } from '../../lib/RestfulModelStore';

const numberFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2 });
const intFormatter    = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 });

export type SalesTuple = { 
  product: FoundRecord<ProductFields>; 
  sales_velocity: any | null;
  category: string;
  velocity: number;
  remaining: number;
  exhaust_date: number;
  name: string;
};

export type InventoryTargetDetailsData = {
  product_groups: 
    {
      name: string;
      sales_tuples: SalesTuple[];
      status?: "success" | "warning" | "danger"
    }[];
};

type InventoryTargetDetailsRenderState = {
  sort_tuple: {property: keyof SalesTuple, reverse: boolean};
};


export default class ProductSalesTable<D extends InventoryTargetDetailsData> extends React.PureComponent<D,InventoryTargetDetailsRenderState> {
  constructor(props: D) {
    super(props);
    this.state = {
      sort_tuple: { property: "exhaust_date", reverse: false }
    };
  }

  applySort(field: keyof SalesTuple) {
    const existing_sort_tuple = this.state.sort_tuple;
    this.setState({
      sort_tuple: {property: field, reverse: (existing_sort_tuple.property == field) ? !existing_sort_tuple.reverse : false}
    });
  }

  sortClassFor(field: string) {
    const sort_tuple = this.state.sort_tuple;
    if(sort_tuple.property == field)
      return sort_tuple.reverse ? 'sorted-reverse' : 'sorted-forward'
    else
      return 'sorted-neutral';
  }
  
  render() {
    const tableEntries:any = [];
    const state = this.state;

    const sort_tuple   = state.sort_tuple;
    const sort_conditions = [
      {property: "loading", reverse: false},
      {property: "category"},
      {property: "flower_type"},
      sort_tuple
    ];

    const product_groups = this.props.product_groups;
    if(product_groups.length == 0)
      return <Row><Col md={12} style={{fontStyle: 'italic', color: 'grey'}}>No Products Available</Col></Row>

    product_groups.forEach((pg) => {
      tableEntries.push(
        <Row key={pg.name}>
          <Col md={12} style={{fontWeight: 'bolder'}}>{pg.name}</Col>
        </Row>
      );
      const hidden_products = [];
      pg.sales_tuples.slice().sort(sortsBy.call(null, sort_conditions)).forEach((st) => {
        if(st.product.quantity <= 0 && st.velocity == 0) {
          hidden_products.push(st);
        } else {
          tableEntries.push(
            <SalesVelocityTableItem key={st.product.id as number} sales_tuple={st}/>
          );  
        }
      });
      if(hidden_products.length > 0) {
        tableEntries.push(
          <Row key={'hidden-summary-'+pg.name}><Col md={12} style={{textAlign: 'center', fontStyle: "italic", color: 'grey'}}>hiding {hidden_products.length} items without sales or stock</Col></Row>
        );    
      }
    });
    const st = {};
    
    return <Grid fluid={true}>
      <Row style={{margin: "1em 0", borderBottom: "1px solid grey"}}>
        <Col md={6}>
          <span className={classNames("table-header", this.sortClassFor('velocity'))} onClick={(e) => (this.applySort('velocity'))}>Velocity</span>
          <span style={{padding: "0 1em"}}>/</span>
          <span className={classNames("table-header", this.sortClassFor('name'))} onClick={(e) => (this.applySort('name'))}>Product Name</span>
        </Col>
        <Col md={3} className={classNames("table-header", this.sortClassFor('remaining'))} onClick={(e) => (this.applySort('remaining'))}>
          Remaining
        </Col>
        <Col md={3} className={classNames("table-header", this.sortClassFor('exhaust_date'))} onClick={(e) => (this.applySort('exhaust_date'))}>
          Exhaustion
        </Col>
      </Row>
      {tableEntries}
    </Grid>;
  }
}

class SalesVelocityTableItem extends React.PureComponent<{sales_tuple: SalesTuple}> {
  render() {
    const st = this.props.sales_tuple;

    //Compute some pictograms
    const two_weeks = new Date(); two_weeks.setDate(two_weeks.getDate() + 14); //So gross
    const one_week  = new Date(); one_week.setDate(one_week.getDate() + 7); //So gross
    const now = new Date();
    let pictos;
    if(st.exhaust_date < now.valueOf()) {
      const popover = <Popover id='out-popover'>You have already run out of this item.</Popover>
      pictos = <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={popover}>
          <Glyphicon glyph='alert' style={{color: 'red'}}/>
      </OverlayTrigger>;
    } else if(st.exhaust_date < one_week.valueOf()) {
      const popover = <Popover id='low-popover'>You have less than a 1 week supply.</Popover>;
      pictos = <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={popover}>
          <Glyphicon glyph='alert' style={{color: 'gold'}}/>
      </OverlayTrigger>;
    } else if(st.exhaust_date < two_weeks.valueOf()) {
      const popover = <Popover id='reorder-popover'>You have less than a 2 week supply.</Popover>;
      pictos = <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={popover}>
          <Glyphicon glyph='alert'/>
      </OverlayTrigger>;
    }

    const product_sales_velocity = st.sales_velocity;
    const product = st.product;

    return <Row style={{margin: "1em 0"}}> 
        <Col md={12} style={{fontStyle: 'italic'}}>{product.name}</Col>
        <Col md={6}>{
          product_sales_velocity && `${numberFormatter.format(product_sales_velocity.velocity)} / day (${product_sales_velocity.sold_units} over ${product_sales_velocity.days_in_stock} days)`
        }</Col>
        <Col md={3}>{intFormatter.format(product.quantity)} Remaining</Col>
        <Col md={2}>{product_sales_velocity && product_sales_velocity.exhaust_date}</Col>
        <Col md={1}>{pictos}</Col>
      </Row>;
  }
}

export function sortsBy<T extends {[key: string]: any}, K extends keyof T>(property_tuples: {property: K, reverse?: boolean}[]) {
  return function sortFunc(a: T,b: T) {
    for(const tuple of property_tuples) {
      const {property, reverse} = tuple;
      const tmp = a;
      if(reverse) {
        a = b;
        b = tmp;
      }
      if(typeof(a[property]) === "string") {
        if(a[property] < b[property]) return -1;
        if(a[property] > b[property]) return 1;
      } else if(typeof(a[property]) === 'number') {
        const diff = (a[property] as number) - (b[property] as number);
        if(diff != 0) 
          return diff;
      }
    }
    return 0;
  }
}