"use strict";

import LoaderWrapper, {DataLoader, ComponentProps} from './lib/DataLoader2';

import React from 'react';
import PropTypes from 'prop-types';

import AppNav from './components/Nav';

import ProductPane from './components/ProductPane';
import VendorsPane from './components/VendorsPane';
import Dashboard   from './components/Dashboard';
import LoginForm   from './components/Login';

import { Grid, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.css';
import './analytics.scss';
import RestfulModelStore, { ModelRecord } from './lib/RestfulModelStore';
import {UserFields, Session, User} from './lib/Models';

class Main extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>> {
  render() {
    const props = this.props;
    if(props.loading_session) {
      return <div>Loading… {props.loading_session}, {props.company_id}</div>;
    } else if (props.needs_login === true) {
      return <div><LoginForm store={props.store}/></div>;
    } else if (props.company_id) {
      let main_pane;
      // const subscription = this.state.subscription;

      // if (subscription._loaded && !subscription._found) {
      //   main_pane = <SubscribePane store={store} company_id={this.state.company_id}/>;
      // } else 
      if (!main_pane) {
        switch(props.page) {
          case 'dashboard': main_pane = <Dashboard ux_state={props.ux_state} store={props.store} company_id={props.company_id}/>;break;
          case 'vendors': main_pane = <VendorsPane ux_state={props.ux_state} store={props.store} company_id={props.company_id}/>;break;
          default: main_pane = <Col><ProductPane ux_state={props.ux_state} store={props.store} company_id={props.company_id}/></Col>;break;
        }
      }

      return <div>
        <AppNav ux_state={props.ux_state} store={props.store} company_id={props.company_id}/>
        <Grid>
          <Row>
            {main_pane}
          </Row>
        </Grid>
      </div>;
    } else {
      return <div>There's a problem with your account, please contact support@jointleaf.com</div> 
    }
  }
}

type IncomingProps = {store: RestfulModelStore, ux_state: any};
type LoaderState   = {};
type LoaderData    = {
  session: any,
  user?: ModelRecord<UserFields>,
  company_id?: string,
  page: string,
  loading_session?: boolean,
  needs_login: boolean,
}
export default LoaderWrapper(Main, ['store','ux_state'], (props: IncomingProps, state: LoaderState, dataLoader: DataLoader<IncomingProps,LoaderState,LoaderData>) => {
  const store    = props.store;
  const ux_state = props.ux_state;

  const session = store.s(Session).fetch();
  const user    = session._loaded && session._found && store.m(User).fetch(session.current_user_id) || undefined;

  const existing_company_id = ux_state.get('company_id');
  const company_id = (user && user._loaded && user._found) && 
    (user.accessible_company_ids.find((el) => (el == existing_company_id)) || user.accessible_company_ids[0]) || undefined;

  const page = ux_state.get('page') || 'dashboard';

  return {
    session: session,
    user: user,
    company_id: company_id,
    page: page,
    loading_session: !session._loaded || session._found && user && !user._loaded,
    needs_login: !session._found
  };
});