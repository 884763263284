"use strict";

import React from 'react';

import LoaderWrapper, { ComponentProps, DataLoader } from '../lib/DataLoader2';

import { Alert, ListGroup, ListGroupItem, Row, Col, Panel } from 'react-bootstrap';
import RestfulModelStore, { ModelRecord } from '../lib/RestfulModelStore';
import { CorporateSummaryFields, Company, Entity, CorporateSummary } from '../lib/Models';

type IncomingProps = {store: RestfulModelStore, ux_state: any, company_id: string};
type LoaderState   = {};
type LoaderData    = {
  yesterday_summary?: ModelRecord<CorporateSummaryFields>
}


class Dashboard extends React.PureComponent<ComponentProps<IncomingProps,LoaderState,LoaderData>> {
  render() {
    const props = this.props;
    const yesterday_summary = props.yesterday_summary;
    const summary = (yesterday_summary && yesterday_summary._found) ? (
      <ListGroup>
        <ListGroupItem>Gross Sales: ${yesterday_summary.gross_sales}</ListGroupItem>
        <ListGroupItem>Cogs: ${yesterday_summary.sold_cogs}</ListGroupItem>
        <ListGroupItem>Gross Margin: ${(100*(yesterday_summary.gross_sales - yesterday_summary.sold_cogs) / yesterday_summary.gross_sales).toFixed(2)}%</ListGroupItem>
        <ListGroupItem>Net Federal Taxes (est): ${(0.59 * (yesterday_summary.gross_sales - yesterday_summary.sold_cogs)).toFixed(2)}</ListGroupItem>
      </ListGroup>
    ) : (
      "Loading…"
    );
    return <Row>
      <Col md={4}>
        <Panel>
          <Panel.Heading>How's my business?</Panel.Heading>
          {summary}
        </Panel>
        <Panel>
          What's my Product Distribution?
        </Panel>
      </Col>
      <Col md={8}>
        <DashboardWidget title="What should I merchandize?">
        </DashboardWidget>
        <DashboardWidget title="What do I need?"/>
        <DashboardWidget title="What should I discount?"/>
        <DashboardWidget title="What do I need to do today?"/>
      </Col>
    </Row>
  }
};


class DashboardWidget extends React.PureComponent<{title:string}> {

  render() {
    return <Panel>
        <Panel.Heading>{this.props.title}</Panel.Heading>
        <Panel.Body>{this.props.children}</Panel.Body>
      </Panel>
    ;
  }
}

export default LoaderWrapper(Dashboard, ['store','ux_state'], (props: IncomingProps, state: LoaderState, dataLoader: DataLoader<IncomingProps,LoaderState,LoaderData>) => {
  const store    = props.store;
  const ux_state = props.ux_state;

  console.log("RUNNING WITH", props.company_id);

  const company = store.m(Company).fetch(props.company_id);
  const entity  = company._found && store.m(Entity).fetch(company.entity_id);
  const date    = entity && entity._found && entity.analysis_date;

  const yesterday_summary = (date && store.m(CorporateSummary).queryFor('', {
    company_id: props.company_id,
    date: date
  })[0] || undefined);

  return {
    yesterday_summary: yesterday_summary
  };
});
